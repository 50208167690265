import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj22 = () => {
  return (
    <Layout>
      <SEO title="Magic Square" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-22">
          <div className="py-1-heading">
            <h1>Magic Square</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Magic%20Square"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              We are going to make a magic square.
              <br />
              <br />
              Here, we have to set the numbers in matrix such that
              <br />
              sum of numbers from verticle, horizontal or diagonal remain same.
              <br />
              <br />
              For this we will create those magic numbers by a miracle formula
              :-
              <br />
              M= n (n² + 1) / 2<br />
              <br />
              Later things, are just some concepts of positions of all those
              magic numbers.
              <br />
              Well, which is actually the important part of it.
              <br />
              <br />
              Boom !...The magic is done...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Mathematics (Matrix)</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj22
